<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div v-if="isEditMode">
      <h2 class="my-4 text-4xl font-semibold">Edit Lead</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit Lead</p>
      </div>
      
      <div>
        <router-link
            :to="{ name: 'admin-leads' }"
        >
          <button
              class="lb-dialog-close-btn-x"
          >
            ×
          </button>
        </router-link>
      </div>
    </div>

    <div v-else>
      <h2 class="my-4 text-4xl font-semibold">Add Lead</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Add an lead to the database</p>
      </div>

      <div>
        <router-link
            :to="{ name: 'admin-leads' }"
        >
          <button
              class="lb-dialog-close-btn-x"
          >
            ×
          </button>
        </router-link>
      </div>
    </div>

    <!-- Lead Content -->
    <div class=" flex flex-wrap">
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-name" class="w-full text-sm font-semibold"
          >First Name</label
        >
        <input
          v-model="form.firstName"
          id="admin-agent-name"
          class="lb-admin-form-input rounded"
          placeholder="First name"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-surname" class="w-full text-sm font-semibold"
          >Last Name</label
        >
        <input
          v-model="form.lastName"
          id="admin-agent-surname"
          class="lb-admin-form-input rounded"
          placeholder="Last name"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-email" class="w-full text-sm font-semibold"
          >Email</label
        >
        <input
          v-model="form.email"
          id="admin-agent-email"
          class="lb-admin-form-input rounded"
          placeholder="Email address"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Phone number</label
        >
        <input
          v-model="form.phone"
          id="admin-agent-phone"
          class="lb-admin-form-input rounded"
          placeholder="Phone number"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Location</label
        >
        <input
          v-model="form.location"
          id="admin-agent-phone"
          class="lb-admin-form-input rounded"
          placeholder="Location"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Interested In</label
        >
        <input
          v-model="form.interestedin"
          id="admin-agent-phone"
          class="lb-admin-form-input rounded"
          placeholder="Interested In"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Source</label
        >
        <input
          v-model="form.source"
          id="admin-agent-phone"
          class="lb-admin-form-input rounded"
          placeholder="Source"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Rating</label
        >
        <select v-model="form.rating" class="lb-admin-form-input rounded inputWidth">
          <option>TBC</option>
          <option>COLD</option>
          <option>WARM</option>
          <option>HOT</option>
          <option>SALE</option>
        </select>
      </div>
      <div
        class="w-full lg:w-1/3 mt-5"
        v-if="hasAccess('leads', 'allocate-agent')"
      >
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Assigned Agent</label  
        >
        <select
          v-model="form.agentId"
          :clearable="true"
          :searchable="true"
          :close-on-select="true"
          class="lb-admin-form-input rounded inputWidth"
        >
          <option value="">Unassigned</option>
          <option
            v-for="agent in agents"
            :value="agent.id"
            :key="agent.id">
            {{ agent.firstName }} {{ agent.lastName }}
          </option>
        </select>
      </div>
    </div>

    <!-- Page Content -->
    
    <div class="w-full  flex flex-wrap mt-10 pt-5 border-t">
      <div>
        <button
          v-if="!isEditMode"
          @click.prevent="addLead"
          class="bg-primary buttons"
        >
          Add Lead
        </button>
      </div>
      <div class="buttonsDiv pl-2">
        <button
          v-if="isEditMode"
          @click.prevent="updateLead(leadID)"
          class="buttons bg-primary"
        >
          Update Lead
        </button>
      </div>
      <div class="buttonsDiv pl-2">
        <button
          v-if="isEditMode && hasAccess('leads', 'delete')"
          @click.prevent="deleteLead(leadID)"
          class="buttons bg-red-600"
        >
          Delete Lead
        </button>
      </div>
      <div class="buttonsDiv pl-2">
        <button
            class="bg-primary buttons"
        >
          <router-link
              class="px-4 py-2"
              :to="{ name: 'admin-leads' }"
          >
            Close
          </router-link>
        </button>
        </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {leadsCollection, profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import { mapGetters } from "vuex";
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminLead",
  mixins: [permissionsMixin],
  data: () => ({
    leadID: null,
    agents: [],
    form: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      location: null,
      rating: "TBC",
      source: null,
      interestedin: null,
      agentId: null,
      modified: null
    },
  }),
  methods: {
      //   changeModified(getLead) {
      // profilesCollection
      //     .doc(getLead.leadID)
      //     .update({
      //       modified: firebase.firestore.Timestamp.now(),
      //     })
      //     .then(() => {
      //       this.$notify({
      //         group: "global",
      //         type: "success",
      //         title: "User Updated",
      //       });
      //     })
      //     .catch((error) => {
      //      this.$notify({
      //         group: "global",
      //       type: "error",
      //         title: "Error updating User",
      //         text: error.message,
      //       });
      //     });
      // },
    getLead(leadID) {
      leadsCollection
        .doc(leadID)
        .get()
        .then((doc) => {
          this.form.firstName = doc.data().firstName;
          this.form.lastName = doc.data().lastName;
          this.form.email = doc.data().email;
          this.form.phone = doc.data().phone;
          this.form.rating = doc.data().rating;
          this.form.location = doc.data().location;
          this.form.source = doc.data().source;
          this.form.interestedin = doc.data().interestedin;
          this.form.agentId = doc.data().agentId;
          this.leadID = doc.id;
          this.form.modified = doc.data().modified;
        });
    },
    async addLead() {
      const leadData = {
        firstName: this.form.firstName || null,
        lastName: this.form.lastName || null,
        email: this.form.email.toLowerCase() || null,
        phone: this.form.phone || null,
        rating: this.form.rating || null,
        location: this.form.location || null,
        source: this.form.source || null,
        agentId: this.form.agentId || null,
        interestedin: this.form.interestedin || null,
        createdby: this.user.data.uid,
        created: firebase.firestore.Timestamp.now(),
      };

      leadsCollection
        .add(leadData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Lead Added",
          });
          this.$router.replace({ name: "admin-leads" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error adding Lead",
            text: error.message,
          });
        });
    },
    updateLead(leadID) {
      const leadData = {
        firstName: this.form.firstName || null,
        lastName: this.form.lastName || null,
        email: this.form.email.toLowerCase() || null,
        phone: this.form.phone || null,
        rating: this.form.rating || null,
        location: this.form.location || null,
        source: this.form.source || null,
        interestedin: this.form.interestedin || null,
        agentId: this.form.agentId || null,
        modifiedby: this.user.data.uid,
        modified: firebase.firestore.Timestamp.now(),
      };
      leadsCollection
        .doc(leadID)
        .update(leadData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Lead Updated",
          });
          this.$router.replace({ name: "admin-leads" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error updating Lead",
            text: error.message,
          });
          this.$router.replace({ name: "admin-leads" });
        });
    },
    deleteLead(leadID) {
      var userConfirmedDelete = confirm(
        "Are you sure you want to delete this lead?"
      );
      if (userConfirmedDelete) {
        leadsCollection
          .doc(leadID)
          .delete()
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Lead Deleted",
            });

            this.$router.replace({ name: "admin-leads" });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: error,
            });
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isEditMode() {
      if (!isNil(this.$route.params.id)) {
        return true;
      }
      return false;
    },
  },
  created() {
    profilesCollection
      .where("role", "==", "agent")
      // .where("role", "==", "agent-principal")
      .get()
      .then((snap) => {
        let agents = [];
        snap.forEach((doc) => {
          agents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            contact: doc.data().formatNationalContactNumber,
            created: doc.data().created,
            role: doc.data().role,
            order: doc.data().order,
            id: doc.id,
          });
        });
        this.agents = agents;
      });
  },
  mounted() {
    if (!isNil(this.$route.params.id)) {
      const leadID = this.$route.params.id;
      this.getLead(leadID);
    }
  },
};
</script>
<style>
.inputWidth {
  width: 70%;
  position:relative;
}
.buttons {
  width: 130px;
  height: 35px;

  font-weight: 550;
  border-radius: 3px;
  font-display: bold;
  color:white;
}

.buttonsDiv {
  margin-top: 5px;
}
</style>
